export const SIZE_GUIDES_ORDER = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];

export const SIZE_GUIDE_WITH_MODELS = [
  'KU10025-001',
  'KU10025-002',
  'KU10026-001',
  'KU10028-001',
  'KU10028-002',
  'KU10030-001',
  'KU10030-002',
  'KU10032-001',
  'KU10032-002',
  'KU10033-001',
  'KU10033-002',
];

export const STANDARD_SIZE_GUIDE = ['EU', 'US', 'UK'];

export const TARGET_SIZE_LABEL_MARKETS = ['US', 'GB', 'XI'];

export const SOCKS_SIZE_ORDER = ['S', 'M', 'L'];

export const COUNTRIES_USING_INCHES = ['US', 'GB', 'AU'];
export const DEFAULT_GUIDE_UNITS = ['cm', 'mm'];
export const UNIT_LABELS_MAPPER = {
  cm: '',
  mm: '.mm',
  in: '.in',
};
