type Args = Array<string | undefined | null | Args | Record<string, boolean>>;

/**
 * merge classes into one string
 * You can provide multiple arguments, each argument can be a string, array, object , null or undefined
 * for objects the keys will be the classnames and the value is a boolean that indicates if the classname should be added or not(true for add)
 * @example
 * mergeClasses('a', {'b': true, 'c': false}, null, undefined, ['d']);
 * // returns 'a b d'
 */
export function mcl(...args: Args): string {
  const classes = new Set();
  for (const arg of args) {
    if (!arg) continue;
    if (typeof arg === 'number') continue;
    // strings
    if (typeof arg === 'string') {
      classes.add(arg.trim());
    }
    // we don't repeat the implementation of mergeClasses as it would be the same. So we just call it recursively.
    if (Array.isArray(arg)) {
      classes.add(mcl(...arg));
    }
    // check if it's an object
    if (typeof arg === 'object') {
      for (const [key, value] of Object.entries(arg)) {
        if (value === true) {
          classes.add(key.trim());
        }
      }
    }
  }
  return Array.from(classes).join(' ');
}
