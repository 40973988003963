import * as ScrollAreaPrimitive from '@radix-ui/react-scroll-area';
import { forwardRef } from 'react';

import { mcl } from 'utils/class-names';

const ScrollArea = forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.Root> & { transparent?: boolean }
>(({ className, children, transparent = false, ...props }, ref) => (
  <ScrollAreaPrimitive.Root ref={ref} className={mcl('overflow-hidden', className)} {...props}>
    <ScrollAreaPrimitive.Viewport className="h-full w-full rounded-[inherit]">{children}</ScrollAreaPrimitive.Viewport>
    <ScrollBar transparent={transparent} />
    <ScrollAreaPrimitive.Corner />
  </ScrollAreaPrimitive.Root>
));
ScrollArea.displayName = ScrollAreaPrimitive.Root.displayName;

const ScrollBar = forwardRef<
  React.ElementRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar>,
  React.ComponentPropsWithoutRef<typeof ScrollAreaPrimitive.ScrollAreaScrollbar> & { transparent?: boolean }
>(({ className, orientation = 'vertical', transparent, ...props }, ref) => (
  <ScrollAreaPrimitive.ScrollAreaScrollbar
    ref={ref}
    orientation={orientation}
    className={mcl(
      'flex touch-none select-none transition-colors',
      orientation === 'vertical' ? 'h-full w-2 border-l border-l-transparent p-[1px]'
      : orientation === 'horizontal' ? 'h-2 border-t border-t-transparent p-[1px]'
      : undefined,
      className,
    )}
    {...props}
  >
    <ScrollAreaPrimitive.ScrollAreaThumb className={mcl('relative flex-1', transparent ? '' : 'bg-neutral-inverted/60')} />
  </ScrollAreaPrimitive.ScrollAreaScrollbar>
));
ScrollBar.displayName = ScrollAreaPrimitive.ScrollAreaScrollbar.displayName;

export { ScrollArea, ScrollBar };
