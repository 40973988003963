import { useState, useEffect, useRef, useMemo } from 'react';
import { Modal, Spin } from 'antd';
import { DialogTrigger } from 'react-aria-components';

import RecommendedBag from 'components/recommendedBag';
import { formatCurrency, getCookie, COOKIES, eraseCookie, getFixedPrice, obtainVoucherPrices } from 'utils/helpers';
import { FAMILIES } from 'utils/constants/products';
import { TARGET_SIZE_LABEL_MARKETS } from 'utils/constants/sizes';
import { ProgressiveImg } from 'components/base';
import { getCart } from 'actions/products';
import { dataLayerHandleEvent } from 'utils/dataLayers';
import { parseBagEcommerceGTM, parseBagItemsEcommerceGA4 } from 'utils/gtmUtils';
import { LabCartIcon } from 'components/camperlab/icons/cart';

import { Drawer, DrawerHeader, DrawerRoot, DrawerX } from 'components/ui/drawer';
import { Link } from 'components/ui/link';

import { useUser } from 'context/user';
import { useI18n } from 'context/i18n';
import useDelayedState from 'hooks/useDelayedState';
import { useIsMounted, useLink, useLocale } from 'hooks';

import styles from './style.module.css';
import { sortAndOrderMiniBag } from 'utils/bag';
import { getCountryFromMarket } from 'utils/translations';
import { getDomainValue } from 'utils/domain';
import { Button } from 'components/ui/button';
import { ScrollArea } from 'components/ui/scroll-area';

import type { TBag, TBagItem } from 'types/bag';

function MiniCartItemCamperlab({ product, onDelete }) {
  const { profileData, t, contextFilters, activatedPrevouchers } = useI18n();
  const { locale, country: localeCountry } = useLocale();

  const country = getCountryFromMarket(localeCountry);

  const showPrice = product.parentId !== undefined ? false : true;
  let parsedTarget = 'M';
  if (country === 'US') {
    parsedTarget = 'US';
  } else if (['M', 'W'].includes(product.target)) {
    parsedTarget = product.target;
  }

  const useSizeEquivalence =
    product.size && product.sizeLabel && product.size !== product.sizeLabel && [FAMILIES.SHOES, FAMILIES.ACCESSORIES].includes(Number(product.family));

  const sizeLabel =
    useSizeEquivalence && !TARGET_SIZE_LABEL_MARKETS.includes(localeCountry) ?
      <span className={styles.size}>{`${product.sizeLabel} US / ${product.size} EU`}</span>
    : <span className={styles.size}>
        {getDomainValue({
          camperlab: `${t('bolsa', 'camperlab.size')} `,
        })}
        {product.camperLabPan === true ?
          'US'
        : product.sizeLabel || product.size ?
          country
        : ''}{' '}
        {TARGET_SIZE_LABEL_MARKETS.includes(localeCountry) && product.family && Number(product.family) === FAMILIES.SHOES ?
          `${product.gender ?? product.target} ${product.sizeLabel || product.size}`
        : product.sizeLabel || product.size}
      </span>;

  const fixedPrice = getFixedPrice(localeCountry, profileData, product?.price?.current);
  const voucherPriceInfo = obtainVoucherPrices({ ...product, prices: product.price }, t, locale, contextFilters, activatedPrevouchers, false, true);
  let info: Record<string, any> = {};
  const oldDiscount = product.price.discount;

  if (voucherPriceInfo) {
    info = voucherPriceInfo.info || {};

    if (!voucherPriceInfo?.message) {
      product.price.voucherDiscount = info.discount;

      const infoOverride = { ...info };

      delete infoOverride.discount;
      product.price = { ...product.price, ...infoOverride };
    }
  }
  const { price } = product;
  const { voucherType } = price;
  let { voucherDiscount } = price;

  if (voucherDiscount !== undefined && voucherDiscount < 0) {
    voucherDiscount *= -1;
  }

  const discountToDisplay =
    oldDiscount ?
      voucherType === 'original.price' ?
        voucherDiscount || oldDiscount
      : oldDiscount
    : voucherDiscount || oldDiscount;

  const voucherTypeToUseInLabel = oldDiscount !== undefined ? voucherType : 'original.price';
  let appliedLabel = t('listado.productos', `voucher.price.message.${voucherTypeToUseInLabel}.applied`);

  if (voucherType === 'final.price' && oldDiscount !== undefined) {
    appliedLabel = appliedLabel.replace('<TAG discount>', voucherDiscount);
  }

  const appliedLabelToDisplay = info?.appliedLabel || voucherType ? appliedLabel : null;

  const priceElement =
    showPrice ?
      <div className="flex flex-shrink-0 flex-col justify-self-end text-end text-sm font-bold lg:text-xs">
        <span data-fp={fixedPrice} data-ge-price="data-ge-price" data-ge-basket-productsaleprice="data-ge-basket-productsaleprice">
          {product.price.currentFormated || `${product.price.current} ${formatCurrency(product.price.currency)}`}
        </span>

        <span className="text-xs lg:text-xxs">
          {discountToDisplay && discountToDisplay > 0 && (
            <span
              className={`${product.price.discountFromVoucher ? `${styles.itemDiscount} ${styles.discountByVoucher}` : styles.itemDiscount}`}
            >{`-${discountToDisplay}%`}</span>
          )}

          {product.price.previous && product.price.previous > product.price.current && (
            <s data-ge-basket-productlistprice="data-ge-basket-productlistprice" className={styles.itemDiscount}>
              {product.price.previousFormated || `${product.price.previous} ${formatCurrency(product.price.currency)}`}
            </s>
          )}
        </span>
      </div>
    : null;

  const deleteButton =
    onDelete ?
      <Button variant="none" className="mt-2.5 self-start text-xs lg:text-xxs" onPress={() => onDelete(product.id)}>
        <span className="underline">{t('generico', 'boton.borrar')}</span>
      </Button>
    : null;

  return (
    <div className={`flex w-full flex-row gap-2.5 text-sm lg:text-xs`}>
      <a href={product.url} className="aspect-[2/3] w-[112px] self-start overflow-hidden bg-faint [&_img]:aspect-[2/3] [&_img]:w-full [&_img]:object-contain">
        <ProgressiveImg
          src={product.thumbnail}
          alt={`bag image for ${product.productId}`}
          /* le paso las otherProps directamente para que funcione en integra */
          /* @ts-ignore */
          iscamperone={false}
        />
      </a>
      <div className={`m-0 flex flex-grow flex-col font-bold`}>
        <p className="mb-0 uppercase">{product.name}</p>
        {sizeLabel}
        {deleteButton}
        {showPrice && appliedLabelToDisplay ?
          <span className="justify-self-end text-sm font-bold text-success lg:text-xs">{appliedLabelToDisplay}</span>
        : null}
      </div>
      {priceElement}
    </div>
  );
}

export function MiniBagCamperlab({ isInSalePath }) {
  const i18nContext = useI18n();
  const {
    t,
    profileData,
    setBagLength,
    setBag,
    setBagHasMemberOnlyProduct,
    setRecommendedProductsForBag,
    promoLabel,
    setPromoLabel,
    promoLabelType,
    setPromoLabelType,
  } = i18nContext;
  const { bag }: { bag: TBag } = i18nContext;

  const { contextFilters, activatedPrevouchers } = useUser();
  const isMounted = useIsMounted();
  const { locale, country: market } = useLocale();
  // const [ bag, setBag ] = useState(null);
  const [visible, setVisible] = useDelayedState(false);
  const [loading, setLoading] = useState(false);
  const { emptyBagCollections, config } = profileData;
  const bagRef = useRef(null);
  const timer: { current: ReturnType<typeof setTimeout> } = useRef();
  const previousBagLength = useRef(undefined);
  const initialLoad = useRef(true);

  const showFreeShipping = config?.show_free_shipping || false;
  const showGiftPurchase = config?.show_gift_purchase || false;
  const showProducts = bag && bag.length > 0;
  const orderedBag = useMemo(() => sortAndOrderMiniBag({ bag: bag ?? [], locale, currency: profileData?.currencySap ?? 'EUR' }), [bag, locale]);
  const total =
    orderedBag && orderedBag.length > 0 ?
      JSON.parse(JSON.stringify(orderedBag)).reduce((accumulator, current) => {
        if (current.parentId) return accumulator;
        const voucherPriceInfo = obtainVoucherPrices({ ...current, prices: current.price }, t, locale, contextFilters, activatedPrevouchers, false, true);
        let currentPrice = current.price.current;

        if (voucherPriceInfo) {
          if (!voucherPriceInfo?.message) {
            currentPrice = Math.round(voucherPriceInfo.info.current);
          }
        }
        return accumulator + currentPrice;
      }, 0)
    : 0;

  const { currency } = profileData;
  const onlyOneItem = orderedBag && orderedBag.length === 1;
  const sunneiProducts = orderedBag?.filter((item) => item.sunnei === true);
  const hasOnlyOneSunnei = sunneiProducts?.length === 1;

  const handleOnClickRemoveBtn = async (id) => {
    setLoading(true);
    const cookiesBagId = getCookie(COOKIES.BAG);
    const employeeId = getCookie(COOKIES.EMPLOYEE_ID) || '';
    const voucher = getCookie(COOKIES.BONDS) || '';
    const zipCode = getCookie(COOKIES.ZIP) || '';
    const itemIndex = bag.findIndex((item) => item.id === id);
    const tax = zipCode && zipCode !== '' ? { zipcode: zipCode } : {};

    let itemToDelete: TBagItem;
    // Filtramos la bolsa para quitar el producto y cualquier producto que pertenezca a ese pack
    const filteredNewBag: TBag = bag.reduce((acc, bagItem) => {
      if (bagItem.id === id || bagItem.parentId === id) {
        if (bagItem.id === id) itemToDelete = bagItem;
        return acc;
      }
      return [...acc, { code: bagItem.productId, ...bagItem }];
    }, []);
    // Aqui vemos si queda en la bolsa algun producto con el mismo productId que el que vamos a borrar y si no lo hay quitamos de los recomendados aquellos con su originalProductId igual al productId del producto que vamos a borrar
    const hasSomeOfTheSameID = filteredNewBag.some((item) => item.productId === itemToDelete?.productId);
    if (!hasSomeOfTheSameID) {
      setRecommendedProductsForBag((prev) =>
        prev.filter((recommendedItem) => recommendedItem.originalProductId !== itemToDelete?.productId).map((item, i) => ({ ...item, order: i })),
      );
    }

    try {
      let updatedPromoLabel = null;
      const result = await getCart({
        lang: locale,
        products: filteredNewBag,
        bondValue: voucher,
        tax,
        hashOrderId: cookiesBagId,
        employeeId,
      });
      if (result) {
        dataLayerHandleEvent({
          event: 'remove_from_cart',
          ecommerce: {
            currency: profileData?.currencySap,
            value: itemToDelete?.price?.current,
            items: parseBagItemsEcommerceGA4([itemToDelete], itemIndex + 1),
          },
          cart: {
            items: parseBagItemsEcommerceGA4(filteredNewBag),
          },
        });
        let updatedPromoLabelType = 'progress';
        if (showFreeShipping) {
          updatedPromoLabel = result?.freeShippingLabel || null;
        }
        if (showGiftPurchase) {
          updatedPromoLabel = result?.gwplabel || result?.gwplabelObtained || result?.freeShippingLabel || null;
          if (result?.gwplabelObtained && result?.gwplabelObtained !== '') {
            updatedPromoLabel = result?.gwplabelObtained;
            updatedPromoLabelType = 'obtained';
          }
        }
        const newShipments = result.productShipmentCost.shipmentsCosts;
        const isEmptyBag = newShipments.length === 0 || result.productShipmentCost.products.length === 0;
        if (filteredNewBag.length === 0 || isEmptyBag) {
          eraseCookie(COOKIES.BAG);
          eraseCookie(COOKIES.BAG_DATE);
        }
        setBagLength(isEmptyBag ? 0 : filteredNewBag.length);
        const hasMemberOnlyProduct = isEmptyBag ? false : filteredNewBag.filter((product) => product.membersOnly === true).length > 0;
        setBagHasMemberOnlyProduct(hasMemberOnlyProduct);
        setBag(isEmptyBag ? [] : filteredNewBag);
        setPromoLabel(updatedPromoLabel);
        setPromoLabelType(updatedPromoLabelType);
        setLoading(false);
      }
    } catch (error) {
      // handle error
      setLoading(false);
      console.error(error);
      Modal.error({
        title: t('bolsa', 'error.borrar.producto.titulo', 'An error has occurred'),
        content: t('bolsa', 'error.borrar.producto.descripcion', 'We are sorry. The product could not be removed.'),
      });
    }
  };

  useEffect(() => {
    if (window !== undefined) {
      window.showMiniBag = () => {
        setVisible(true);
      };
    }
  }, [setVisible]);

  useEffect(() => {
    const showBagIfItemAdded = () => {
      if (bagRef.current?.offsetParent === null) return;
      if (initialLoad.current) return;
      if (previousBagLength.current === undefined) {
        return;
      }
      if (previousBagLength.current >= bag.length) {
        return;
      }
      setVisible(true);
      timer.current = setTimeout(() => {
        if (isMounted()) {
          setVisible(false);
        }
      }, 6000);
    };

    showBagIfItemAdded();
    if (bag) {
      initialLoad.current = false;
      previousBagLength.current = bag.length;
    }

    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [bag]);

  const handleClickMiniBagIcon = () => {
    setVisible((prev) => !prev);
    return;
  };

  // Si un usuario interactua con la minibolsa en movil quitar el timeout para que no se cierre.
  const handleInteraction = () => {
    clearTimeout(timer.current);
  };

  return (
    <nav ref={bagRef} className={styles.bagIcon}>
      <Button variant="none" className="flex items-center gap-1 font-primary text-lg font-bold uppercase lg:text-xs" onPress={handleClickMiniBagIcon}>
        <span className="animated-underline inline-flex gap-1.5">
          <LabCartIcon id="lab-cart-icon" className="flex lg:hidden" />
          <span id="lab-cart-text">{t('mi.bolsa', 'bag.widget')}</span>
          {bag && !isInSalePath && bag?.length > 0 ?
            <span id="lab-cart-count" className="inline-flex whitespace-nowrap text-left">
              ( {bag.length} )
            </span>
          : ''}
        </span>
      </Button>

      <DialogTrigger isOpen={visible && !isInSalePath} onOpenChange={handleClickMiniBagIcon}>
        <DrawerRoot className="w-full">
          <Drawer>
            <ScrollArea
              className="relative flex h-[100dvh] max-h-[100dvh] max-w-[100vw] flex-grow flex-col overflow-hidden [&>div>div]:!flex [&>div>div]:h-full [&>div>div]:flex-col [&>div]:flex-col"
              onPointerDown={handleInteraction}
              transparent
            >
              <DrawerHeader label={t('mi.bolsa', 'bag.widget')} className="px-2.5" />
              {/* <div className="sticky top-0 z-[2] mb-32 flex flex-shrink-0 items-center justify-between bg-[#fff] p-2.5">
                <div className="flex items-center md:relative md:top-[5px] md:order-2">
                  <DrawerX absolute={false} />
                </div>
                <p className={`${styles.menuItemTitle} mb-0 flex items-center text-xs font-bold uppercase md:relative md:top-[5px]`}>
                  {t('mi.bolsa', 'bag.widget')}
                </p>
              </div> */}

              <div className="flex-grow">
                {!showProducts && (
                  <div className="px-2.5">
                    <p className="mb-0 text-sm font-bold uppercase lg:text-xs">{t('menu.derecha', 'derecha.bolsa.vacia')}</p>
                    <p className="mb-5 text-sm font-bold lg:text-xs">{t('menu.derecha', 'take.look.collection', '***Take a look at our collection.')}</p>
                    <div className="flex flex-col gap-[5px]">
                      {emptyBagCollections?.map((item) => (
                        <Link key={`collectionButton-${item.title}`} href={item.url} variant="solid">
                          {item.title}
                        </Link>
                      ))}
                    </div>
                  </div>
                )}

                {showProducts && (
                  <div className={styles.spinWrapper}>
                    {/* @ts-ignore */}
                    <Spin spinning={loading}>
                      <ul className={`${styles.bagList} flex flex-col`}>
                        <div className={`${styles.itemsWrapper} ${onlyOneItem ? styles.noScroll : ''} flex-grow px-2.5`}>
                          {orderedBag.map((product, index) => {
                            return (
                              <li data-ge-basket-cartitemid={product.productId} key={`${product.productId}-${index}`} className={`${styles.productItem}`}>
                                <MiniCartItemCamperlab product={product} onDelete={handleOnClickRemoveBtn} />
                                {Array.isArray(product.additionalProducts) && product.additionalProducts.length > 0 ?
                                  <ul className={styles.additionalProducts}>
                                    {product.additionalProducts.map((additionalProduct, additionalIndex) => (
                                      <li key={`${additionalProduct.productId}-${additionalIndex}`} className={styles.productItem}>
                                        <MiniCartItemCamperlab product={additionalProduct} onDelete={() => handleOnClickRemoveBtn(product.id)} />
                                      </li>
                                    ))}
                                  </ul>
                                : null}
                              </li>
                            );
                          })}
                        </div>
                        <div className="mt-20 w-[calc(100vw)] empty:hidden lg:w-[calc(25vw+10px)] lg:min-w-[340px]">
                          <RecommendedBag />
                        </div>
                      </ul>
                      <div className="h-[100px] px-2.5"></div>
                    </Spin>
                  </div>
                )}
              </div>
              {Array.isArray(bag) && bag.length > 0 ?
                <section className="sticky bottom-0 left-0 right-0 bg-clear p-2.5" id="bag-purchase-btn">
                  {hasOnlyOneSunnei && <p className="error" dangerouslySetInnerHTML={{ __html: t('bolsa', 'completa.sunnei') }} />}
                  {promoLabel && <p className="mb-0 text-center text-xxs text-success">{promoLabel}</p>}
                  <p className="mb-0 flex justify-between text-sm font-bold lg:text-xs">
                    <span>{'Total'}</span>
                    <span data-fp={getFixedPrice(market, profileData, total)} data-ge-basket-totals="data-ge-basket-totals">{`${total} ${currency}`}</span>
                  </p>
                  <p className="text-xs text-disabled lg:text-xxs">
                    {getDomainValue({
                      camper: market !== 'US' && market !== 'CA' ? <span>{t('mis.pedidos', 'impuestos.incluidos', 'All taxes included')}</span> : null,
                      camperlab: <span>{t('mis.pedidos', 'impuestos.incluidos', 'All taxes included')}</span>,
                    })}

                    {profileData.freeShipping && profileData.freeShipping === true && profileData.freeShippingOver && profileData.freeShippingOver < total ?
                      <span className="ml-0.5 underline">{t('mis.pedidos', 'free.shipping.included', 'Free Shipping')}</span>
                    : null}
                  </p>
                  <Link href={`/${locale}/bolsa`} variant="solid" className="h-8 px-2 text-sm font-bold uppercase lg:h-6 lg:text-xs">
                    {`${t('menu.derecha', 'derecha.comprar')} ( ${bag?.length ?? '0'} )`}
                  </Link>
                </section>
              : null}
            </ScrollArea>
          </Drawer>
        </DrawerRoot>
      </DialogTrigger>
    </nav>
  );
}

MiniBagCamperlab.displayName = 'MiniBagCamperlab';
